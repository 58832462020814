import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham";
import styled from "styled-components";
import { Radio } from "@lib/components";
import PreviewTransfer from "./previewTransfer";
import { api } from "../../api";
import { RotateLoader } from "@lib/components";
import { margin } from "polished";

interface Props {
	toggleMode: any;
}

const MainWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 42%;
	margin: 0 auto;
`;

const TransferWrapper = styled.div`
	display: inline-flex;
	margin-top: 50px;
`;

const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
`;

const SearchInput = styled.input`
  height: 35px;
  padding: 5px;
  margin-right: 10px;
  font-size: 14px;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 4px;
`;

const SearchButton = styled.button`
  height: 35px;
  background-color: #6558f5;
  color: #ffffff;
  border-radius: 5px;
  padding: 5px 10px;
  margin-right: 10px;
  font-size: 14px;
`;

const ResetButton = styled.button`
  height: 35px;
  background-color: #f56565;
  color: #ffffff;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 14px;
`;

const TableWrapper = styled.div`
	margin-top: 20px;
	width: auto;
`;

const RestaurantTitle = styled.span`
	font-weight: 600;
	font-size: 26px;
	display: block;
`;

const TransferTitle = styled.div`
	font-weight: 600;
	font-size: 26px;
`;

const RadioButton = styled.span`
	margin-left: 30px;
	display: flex;
`;

const PrevewButton = styled.button`
	min-width: 60px;
	height: 35px;
	background-color: #6558f5;
	color: #ffffff;
	border-radius: 5px;
	margin-left: auto;
	padding: 5px 10px;
    font-size: 14px;
`;

const BackButton = styled.button`
	height: 35px;
	background-color: #6558f5;
	color: #ffffff;
	border-radius: 5px;
	min-width: 50px;
    padding: 5px 10px;
    font-size: 14px;
`;

const ButtonWrapper = styled.div`
	margin-top: 70px;
	display: flex;
`
class TransferRestaurant extends React.Component<Props, any> {
	restaurantGridApi: any;
	transferGridApi: any;
	restaurantGridColumnApi: any;
	transferGridColumnApi: any;
	columnTransferDefs: any = [];
	restaurantData = [];
	dataComputed: any = [];
	constructor(props: Props) {
		super(props);
		this.state = {
			loading: false,
			gridTransferLoading: false,
			step: 1,
			columnDefs: [],
			restaurantData: [],
			resellerData: [],
			organizationData: [],
			transferMode: "",
			selectedRowsNew: JSON.parse(localStorage.getItem("selectedRs") as string),
			searchText: '',
			searchColumn: 'name', // Default search column
			resellerSearchText: '',
			resellerSearchColumn: 'name', // Default search column
			orgSearchText: '',
			orgSearchColumn: 'user', // Default search column
			isButtonHovered: false, // State for hover effect
			isResetButtonHovered: false, // State for hover effect
			isResellerButtonHovered: false, // State for hover effect
			isResellerResetButtonHovered: false, // State for hover effect
			defaultRestaurantSearchKey: "limit=10&skip=0",
			defaultResellerRestaurantSearchKey: "limit=10&skip=0",
			defaultOrganizationsSearchKey: "limit=10&skip=0"
		};
		this.onSearchTextChange = this.onSearchTextChange.bind(this);
		this.onSearchColumnChange = this.onSearchColumnChange.bind(this);
		this.onSearchSubmit = this.onSearchSubmit.bind(this);
		this.onSearchReset = this.onSearchReset.bind(this);
		this.handleButtonMouseEnter = this.handleButtonMouseEnter.bind(this);
		this.handleButtonMouseLeave = this.handleButtonMouseLeave.bind(this);

		this.onResellerSearchTextChange = this.onResellerSearchTextChange.bind(this);
		this.onResellerSearchColumnChange = this.onResellerSearchColumnChange.bind(this);
		this.onResellerSearchSubmit = this.onResellerSearchSubmit.bind(this);
		this.onResellerSearchReset = this.onResellerSearchReset.bind(this);
		this.handleResellerButtonMouseEnter = this.handleResellerButtonMouseEnter.bind(this);
		this.handleResellerButtonMouseLeave = this.handleResellerButtonMouseLeave.bind(this);
		this.handleResellerResetButtonMouseEnter = this.handleResellerResetButtonMouseEnter.bind(this);
		this.handleResellerResetButtonMouseLeave = this.handleResellerResetButtonMouseLeave.bind(this);

		this.onOrgSearchSubmit = this.onOrgSearchSubmit.bind(this);
		this.onOrgSearchReset = this.onOrgSearchReset.bind(this);
		this.onOrgSearchColumnChange = this.onOrgSearchColumnChange.bind(this);
		this.onOrgSearchTextChange = this.onOrgSearchTextChange.bind(this);
	}

	loadSeletedRows = (selectedRows: any, girdApi: any) => {
		if (!selectedRows) selectedRows = [];
		girdApi.forEachNode((node: any) => {
			const selectNode = selectedRows.some((row: any) => {
				return row._id === node.data._id;
			});

			if (selectNode) {
				node.setSelected(true, false);
			}
		});
	};

	onRestaurantGridReady = (params: any) => {
		this.restaurantGridApi = params.api;
		this.restaurantGridColumnApi = params.columnApi;
		let selectedRows = JSON.parse(localStorage.getItem("selectedRs") as string);
		this.loadSeletedRows(selectedRows, this.restaurantGridApi);
	};

	onTransferGridReady = (params: any) => {
		this.transferGridApi = params.api;
		this.transferGridColumnApi = params.columnApi;
		let selectedRows = JSON.parse(localStorage.getItem("transferData") as string);
		this.loadSeletedRows(selectedRows, this.transferGridApi);
	};

	onRowSelected = (event: any) => {
		// // Get the input field values
		// const resellerInput = document.getElementById('ag-99-input') as HTMLInputElement;
		// const nameInput = document.getElementById('ag-93-input') as HTMLInputElement;
		// const subdomainInput = document.getElementById('ag-96-input') as HTMLInputElement;
		// const userInput = document.getElementById('ag-102-input') as HTMLInputElement;

		// // Get the trimmed values
		// const resellerId = resellerInput ? resellerInput.value.trim() : '';
		// const name = nameInput ? nameInput.value.trim() : '';
		// const subdomain = subdomainInput ? subdomainInput.value.trim() : '';
		// const user = userInput ? userInput.value.trim() : '';

		// // If all inputs are empty, return early
		// if (!resellerId && !name && !subdomain && !user) {
		// 	return;
		// }

		// Get the grid API
		const gridApi = event.api;

		// // Check if a row was selected
		// if (event.node.selected) {
		// 	gridApi.forEachNode((node: any) => {
		// 		const matchesResellerId = resellerId ? node.data.reseller_id === resellerId : true;
		// 		const matchesName = name ? node.data.name === name : true;
		// 		const matchesSubdomain = subdomain ? node.data.subdomain === subdomain : true;
		// 		const matchesUser = user ? node.data.user === user : true;

		// 		// If the node matches all present criteria, select it
		// 		if ((resellerId ? matchesResellerId : true) &&
		// 			(name ? matchesName : true) &&
		// 			(subdomain ? matchesSubdomain : true) &&
		// 			(user ? matchesUser : true)) {
		// 			node.setSelected(true);
		// 		}
		// 	});
		// 	return;
		//}

		// Check if a row was selected
		// if (event.node.selected) {
		// 	gridApi.forEachNode((node: any) => {
		// 		node.setSelected(true);
		// 	});
		// 	return;
		// }

		// Update selected rows state and save to local storage
		const selectedRows = gridApi.getSelectedRows();
		this.setState({ selectedRows });
		localStorage.setItem('selectedRs', JSON.stringify(selectedRows));
	};

	onSearchTextChange(event: any) {
		this.setState({ searchText: event.target.value });
	}

	onResellerSearchTextChange(event: any) {
		this.setState({ resellerSearchText: event.target.value });
	}

	onOrgSearchTextChange(event: any) {
		this.setState({ orgSearchText: event.target.value });
	}

	handleResetButtonMouseEnter() {
		this.setState({ isResetButtonHovered: true });
	}

	handleResetButtonMouseLeave() {
		this.setState({ isResetButtonHovered: false });
	}

	handleButtonMouseEnter() {
		this.setState({ isButtonHovered: true });
	}

	handleButtonMouseLeave() {
		this.setState({ isButtonHovered: false });
	}

	handleResellerButtonMouseEnter() {
		this.setState({ isResellerButtonHovered: true });
	}

	handleResellerButtonMouseLeave() {
		this.setState({ isResellerButtonHovered: false });
	}

	handleResellerResetButtonMouseEnter() {
		this.setState({ isResellerResetButtonHovered: true });
	}

	handleResellerResetButtonMouseLeave() {
		this.setState({ isResellerResetButtonHovered: false });
	}


	onSearchSubmit(event: any) {
		event.preventDefault();
		const { searchText, previousFilters } = this.state;
		var searchKey = ""
		if (searchText === "") {
			searchKey = this.state.defaultRestaurantSearchKey
		} else {
			searchKey = this.state.searchColumn + "SearchKey=" + searchText
		}
		this.restaurantSearch(searchKey);
	}

	onResellerSearchSubmit(event: any) {
		event.preventDefault();
		const { resellerSearchText, previousFilters } = this.state;
		var searchKey = ""
		if (resellerSearchText === "") {
			searchKey = this.state.defaultResellerRestaurantSearchKey
		} else {
			searchKey = this.state.resellerSearchColumn + "SearchKey=" + resellerSearchText
		}
		this.resellerSearch(searchKey);
	}

	onOrgSearchSubmit(event: any) {
		event.preventDefault();
		const { orgSearchText, previousFilters } = this.state;
		var searchKey = ""
		if (orgSearchText === "") {
			searchKey = this.state.defaultOrganizationsSearchKey
		} else {
			searchKey = this.state.orgSearchColumn + "SearchKey=" + orgSearchText
		}
		this.onOrganizationSearch(searchKey);
	}

	onSearchReset(event: any) {
		event.preventDefault();
		const { searchText, previousFilters } = this.state;
		localStorage.removeItem('selectedRs'); // Remove selectedRs from local storage
		this.setState({ searchText: '' });
		var searchKey = this.state.defaultRestaurantSearchKey
		this.restaurantSearch(searchKey);
	}

	onResellerSearchReset(event: any) {
		event.preventDefault();
		const { resellerSearchText, previousFilters } = this.state;
		this.setState({ resellerSearchText: '' });
		this.resellerSearch(this.state.defaultResellerRestaurantSearchKey);
	}

	onOrgSearchReset(event: any) {
		event.preventDefault();
		const { orgSearchText, previousFilters } = this.state;
		this.setState({ orgSearchText: '' });
		this.onOrganizationSearch(this.state.defaultOrganizationsSearchKey);
	}

	onSearchColumnChange(event: any) {
		this.setState({ searchColumn: event.target.value });
	}

	onResellerSearchColumnChange(event: any) {
		this.setState({ resellerSearchColumn: event.target.value });
	}

	onOrgSearchColumnChange(event: any) {
		this.setState({ orgSearchColumn: event.target.value });
	}

	onRestaurantRowChanged = () => {
		this.dataComputed = this.restaurantGridApi.getSelectedRows();
		const selectedRs = this.restaurantGridApi.getSelectedRows();
		localStorage.setItem("selectedRs", JSON.stringify(selectedRs));
	};

	onTransferRowChanged = () => {
		const transferData = this.transferGridApi.getSelectedRows();
		localStorage.setItem("transferData", JSON.stringify(transferData));
	};

	defaultcolumnDefs: any = [
		{
			headerName: "Name",
			field: "name",
			headerCheckboxSelection: true,
			checkboxSelection: true,
			showDisabledCheckboxes: true,
			sortable: true,
			filter: "agTextColumnFilter",
		},
		{
			headerName: "Subdomain",
			field: "subdomain",
			sortable: true,
			filter: "agTextColumnFilter",
		},
		{
			headerName: "Reseller",
			field: "reseller_id",
			sortable: true,
			filter: "agTextColumnFilter",
		},
		{
			headerName: "User",
			field: "user",
			sortable: true,
			filter: "agTextColumnFilter",
		},
	];

	defaultColDefProperty = {
		flex: 1,
		floatingFilter: true,
	};

	setPage = (page: number) => {
		this.setState({ step: page });
	};

	onClickPreview = () => {
		this.setState({ step: 2 });
	};

	onResellerSelected = async () => {
		this.setState({ gridTransferLoading: true });
		this.columnTransferDefs = [
			{
				headerName: "Reseller",
				field: "_id",
				sortable: true,
				filter: "agTextColumnFilter",
				checkboxSelection: true,
			},
			{
				headerName: "Name",
				field: "name",
				sortable: true,
				filter: "agTextColumnFilter",
			},
		];
		const resellerData = await api.getResellers(this.state.defaultResellerRestaurantSearchKey);
		localStorage.removeItem('transferData');
		this.setState({
			resellerData: resellerData.resellers,
			transferMode: "to_reseller",
			gridTransferLoading: false
		});
	};

	onOrganizationSelected = async () => {
		this.setState({ gridTransferLoading: true });
		this.columnTransferDefs = [
			{
				headerName: "User",
				field: "email",
				sortable: true,
				filter: "agTextColumnFilter",
				checkboxSelection: true,
			},
			{
				headerName: "Restaurant Name",
				field: "restaurant_name",
				sortable: true,
				filter: "agTextColumnFilter",
			},
			{
				headerName: "Reseller",
				field: "reseller_id",
				sortable: true,
				filter: "agTextColumnFilter",
			},
			{
				headerName: "Name",
				field: "name",
				sortable: true,
				filter: "agTextColumnFilter",
			}
		];
		const organizationData = await api.getOrganizations(this.state.defaultOrganizationsSearchKey);
		localStorage.removeItem('transferData');
		this.setState({
			organizationData: organizationData.organizations,
			transferMode: "to_organization",
			gridTransferLoading: false,
		});
	};

	async componentDidMount() {
		this.setState({ loading: true });
		const restaurants = await api.getRestaurants(this.state.defaultRestaurantSearchKey);
		await this.onResellerSelected();
		this.setState({ restaurantData: restaurants.restaurants, loading: false });
	}

	async restaurantSearch(searchKey: string) {
		const restaurants = await api.getRestaurants(searchKey);
		this.setState({ restaurantData: restaurants.restaurants, loading: false });
	}

	async resellerSearch(searchKey: string) {
		this.setState({ gridTransferLoading: true });
		this.columnTransferDefs = [
			{
				headerName: "Reseller",
				field: "_id",
				sortable: true,
				filter: "agTextColumnFilter",
				checkboxSelection: true,
			},
			{
				headerName: "Name",
				field: "name",
				sortable: true,
				filter: "agTextColumnFilter",
			},
		];
		const resellerData = await api.getResellers(searchKey);
		localStorage.removeItem('transferData');
		this.setState({
			resellerData: resellerData.resellers,
			transferMode: "to_reseller",
			gridTransferLoading: false
		});
	}

	onOrganizationSearch = async (searchKey: string) => {
		this.setState({ gridTransferLoading: true });
		this.columnTransferDefs = [
			{
				headerName: "User",
				field: "email",
				sortable: true,
				filter: "agTextColumnFilter",
				checkboxSelection: true,
			},
			{
				headerName: "Restaurant Name",
				field: "restaurant_name",
				sortable: true,
				filter: "agTextColumnFilter",
			},
			{
				headerName: "Reseller",
				field: "reseller_id",
				sortable: true,
				filter: "agTextColumnFilter",
			},
			{
				headerName: "Name",
				field: "name",
				sortable: true,
				filter: "agTextColumnFilter",
			}
		];
		const organizationData = await api.getOrganizations(searchKey);
		localStorage.removeItem('transferData');
		this.setState({
			organizationData: organizationData.organizations,
			transferMode: "to_organization",
			gridTransferLoading: false,
		});
	};

	onTransferSuccess = async () => {
		this.setState({ loading: true });
		const restaurants = await api.getRestaurants(this.state.defaultRestaurantSearchKey);
		await this.onResellerSelected();
		this.setState({ restaurantData: restaurants.restaurants, loading: false });
	}

	computedGridHeight = () => {
		let tableRestaurantHeight = "";
		let tableOrgHeight = "";
		let domLayoutReseller = "autoHeight";
		let domLayoutOrg = "autoHeight";

		if (this.state.resellerData.length > 5) {
			tableRestaurantHeight = "200px";
			domLayoutReseller = "normal";
		}
		if (this.state.organizationData.length > 5) {
			tableOrgHeight = "200px";
			domLayoutOrg = "normal";
		}

		return {
			tableRestaurantHeight,
			tableOrgHeight,
			domLayoutReseller,
			domLayoutOrg,
		};
	};

	toggleMode = (newMode: string) => {
		this.props.toggleMode(newMode);
	};

	render() {
		const { tableRestaurantHeight, tableOrgHeight, domLayoutReseller, domLayoutOrg } = this.computedGridHeight();
		const formStyle = {
			display: 'flex',
			alignItems: 'center',
			marginBottom: '20px',
			marginTop: '20px',
		};

		const selectStyle = {
			padding: '10px',
			marginRight: '10px',
			border: '1px solid #ccc',
			borderRadius: '4px',
			fontSize: '14px',
			width: '150px',
		};

		const inputStyle = {
			flex: 1,
			padding: '10px',
			marginRight: '10px',
			border: '1px solid #ccc',
			borderRadius: '4px',
			fontSize: '14px',
		};

		const buttonBaseStyle = {
			padding: '10px',
			backgroundColor: '#6558f5',
			color: 'white',
			border: 'none',
			borderRadius: '4px',
			cursor: 'pointer',
			transition: 'background-color 0.3s ease',
		};

		const resetButtonBaseStyle = {
			marginLeft: '5px',
			padding: '10px',
			backgroundColor: '#f56565',
			color: 'white',
			border: 'none',
			borderRadius: '4px',
			cursor: 'pointer',
			transition: 'background-color 0.3s ease',
		};

		const resetResellerButtonBaseStyle = {
			marginLeft: '5px',
			padding: '10px',
			backgroundColor: '#f56565',
			color: 'white',
			border: 'none',
			borderRadius: '4px',
			cursor: 'pointer',
			transition: 'background-color 0.3s ease',
		};

		const buttonHoverStyle = {
			backgroundColor: '#0056b3',
		};

		const resetButtonHoverStyle = {
			backgroundColor: '#ff3300',
		};

		const resetResellerButtonHoverStyle = {
			backgroundColor: '#ff3300',
		};

		const buttonStyle = this.state.isButtonHovered
			? { ...buttonBaseStyle, ...buttonHoverStyle }
			: buttonBaseStyle;

		const resetButtonStyle = this.state.isResetButtonHovered
			? { ...resetButtonBaseStyle, ...resetButtonHoverStyle }
			: resetButtonBaseStyle;

		const resellerButtonStyle = this.state.isResellerButtonHovered
			? { ...buttonBaseStyle, ...buttonHoverStyle }
			: buttonBaseStyle;

		const resetResellerButtonStyle = this.state.isResellerResetButtonHovered
			? { ...resetResellerButtonBaseStyle, ...resetResellerButtonHoverStyle }
			: resetResellerButtonBaseStyle;

		return (
			<>
				{this.state.loading && <RotateLoader size={3} />}
				{this.state.step === 1 && !this.state.loading && (
					<>
						<MainWrapper>
							<RestaurantTitle>
								Restaurant to Transfer
							</RestaurantTitle>
							<p>Select one more</p>
							<p style={{ color: 'red', fontWeight: 'bold' }}>Please search for correct keyword before selecting any restaurant</p>
							<p>To uncheck, auto checked list, double-click on the already selected subdomain and then uncheck it.</p>
							<form style={formStyle} onSubmit={this.onSearchSubmit} onReset={this.onSearchReset}>
								<select
									style={selectStyle}
									value={this.state.searchColumn}
									onChange={this.onSearchColumnChange}
								>
									<option value="name">Name</option>
									<option value="subdomain">Subdomain</option>
									<option value="reseller">Reseller</option>
									<option value="user">User</option>
								</select>
								<input
									type="text"
									value={this.state.searchText}
									onChange={this.onSearchTextChange}
									placeholder="Search..."
									style={inputStyle}
								/>
								<button
									type="submit"
									style={buttonStyle}
									onMouseEnter={this.handleButtonMouseEnter}
									onMouseLeave={this.handleButtonMouseLeave}
								>
									Search
								</button>
								<button
									type="reset"
									style={resetButtonStyle}
									onMouseEnter={this.handleResetButtonMouseEnter}
									onMouseLeave={this.handleResetButtonMouseLeave}
								>
									Reset
								</button>
							</form>
							<TableWrapper>
								<div
									className="ag-theme-balham"
									style={{ width: "100%", height: "250px" }}>
									<AgGridReact
										columnDefs={this.defaultcolumnDefs}
										onSelectionChanged={this.onRestaurantRowChanged}
										rowData={this.state.restaurantData}
										defaultColDef={this.defaultColDefProperty}
										onGridReady={this.onRestaurantGridReady}
										onRowSelected={this.onRowSelected}
										suppressRowClickSelection={true}
										rowSelection="multiple"
									/>
								</div>
							</TableWrapper>

							<TransferWrapper>
								<TransferTitle>Transfer To</TransferTitle>
								<RadioButton>
									<Radio
										id={"reseller"}
										name="reseller"
										checked={this.state.transferMode === "to_reseller"}
										title={"Reseller"}
										value={"reseller"}
										onChange={() => this.onResellerSelected()}
									/>
								</RadioButton>
								<RadioButton>
									<Radio
										id={"organization"}
										name="organization"
										checked={this.state.transferMode === "to_organization"}
										title={"Organization"}
										value={"organization"}
										onChange={() => this.onOrganizationSelected()}
									/>
								</RadioButton>
							</TransferWrapper>
							<p>Select one reseller</p>
							{this.state.gridTransferLoading && (
								<RotateLoader size={3} />
							)}
							{this.state.transferMode === "to_reseller" &&
								!this.state.gridTransferLoading && (
									<><form style={formStyle} onSubmit={this.onResellerSearchSubmit} onReset={this.onResellerSearchReset}>
										<select
											style={selectStyle}
											value={this.state.resellerSearchColumn}
											onChange={this.onResellerSearchColumnChange}
										>
											<option value="name">Name</option>
											<option value="reseller">Reseller</option>
										</select>
										<input
											type="text"
											value={this.state.resellerSearchText}
											onChange={this.onResellerSearchTextChange}
											placeholder="Search..."
											style={inputStyle}
										/>
										<button
											type="submit"
											style={resellerButtonStyle}
											onMouseEnter={this.handleResellerButtonMouseEnter}
											onMouseLeave={this.handleResellerButtonMouseLeave}
										>
											Search
										</button>
										<button
											type="reset"
											style={resetResellerButtonStyle}
											onMouseEnter={this.handleResellerResetButtonMouseEnter}
											onMouseLeave={this.handleResellerResetButtonMouseLeave}
										>
											Reset
										</button>
									</form><TableWrapper>
											<div
												className="ag-theme-balham"
												style={{ width: "100%", height: tableRestaurantHeight }}>
												<AgGridReact
													columnDefs={this.columnTransferDefs}
													onSelectionChanged={this.onTransferRowChanged}
													rowData={this.state.resellerData}
													defaultColDef={this.defaultColDefProperty}
													onGridReady={this.onTransferGridReady}
													domLayout={domLayoutReseller} />
											</div>
										</TableWrapper></>
								)}
							{this.state.transferMode === "to_organization" &&
								!this.state.gridTransferLoading && (
									<><form style={formStyle} onSubmit={this.onOrgSearchSubmit} onReset={this.onOrgSearchReset}>
										<select
											style={selectStyle}
											value={this.state.orgSearchColumn}
											onChange={this.onOrgSearchColumnChange}
										>
											<option value="restaurant">Restaurant Name</option>
											<option value="user">User Email</option>
											<option value="name">Name</option>
											<option value="reseller">Reseller</option>
										</select>
										<input
											type="text"
											value={this.state.orgSearchText}
											onChange={this.onOrgSearchTextChange}
											placeholder="Search..."
											style={inputStyle}
										/>
										<button
											type="submit"
											style={resellerButtonStyle}
											onMouseEnter={this.handleResellerButtonMouseEnter}
											onMouseLeave={this.handleResellerButtonMouseLeave}
										>
											Search
										</button>
										<button
											type="reset"
											style={resetResellerButtonStyle}
											onMouseEnter={this.handleResellerResetButtonMouseEnter}
											onMouseLeave={this.handleResellerResetButtonMouseLeave}
										>
											Reset
										</button>
									</form><TableWrapper>
											<div
												className="ag-theme-balham"
												style={{ width: "100%", height: tableOrgHeight }}>
												<AgGridReact
													columnDefs={this.columnTransferDefs}
													onSelectionChanged={this.onTransferRowChanged}
													rowData={this.state.organizationData}
													defaultColDef={this.defaultColDefProperty}
													onGridReady={this.onTransferGridReady}
													domLayout={domLayoutOrg}
												/>
											</div>
										</TableWrapper></>
								)}
							<ButtonWrapper>
								<BackButton
									onClick={() => { this.props.toggleMode("N") }}>
									Back
								</BackButton>
								<PrevewButton onClick={this.onClickPreview}>
									Preview
								</PrevewButton>
							</ButtonWrapper>
						</MainWrapper>
					</>
				)}

				{this.state.step === 2 && !this.state.loading && (
					<PreviewTransfer
						onTransferSuccess={this.onTransferSuccess}
						processedData={this.dataComputed}
						setPage={this.setPage}
						transferType={this.state.transferMode}
					/>
				)}
			</>
		);
	}
}


export default TransferRestaurant;
